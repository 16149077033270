import { TOKENS, SPENDER_ADDRESS, PROTOCOLS } from './constants'
import { ACTIONS } from '../actions'

const YIELD_BEARING_ASSET = '0x57319d41f71e81f3c65f2a47ca4e001ebafd4f33'
const TOKEN_ISSUER = '0x57319d41f71e81f3c65f2a47ca4e001ebafd4f33-1656547200'

export default [
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.JOE],
        received: [TOKENS.xJOE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.xJOE],
        received: [TOKENS.OT_xJOE, TOKENS.YT_xJOE],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_xJOE, TOKENS.USDC],
        received: [TOKENS.OT_xJOE_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_xJOE_USDC],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_xJOE, TOKENS.USDC],
        received: [TOKENS.YT_xJOE_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_xJOE_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.JOE],
        received: [TOKENS.xJOE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.xJOE],
        received: [TOKENS.OT_xJOE, TOKENS.YT_xJOE],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_xJOE, TOKENS.USDC],
        received: [TOKENS.OT_xJOE_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_xJOE_USDC],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_xJOE, TOKENS.USDC],
        received: [TOKENS.YT_xJOE_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_xJOE_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.JOE, TOKENS.USDC],
    baseTokenAddresses: [TOKENS.JOE, TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_xJOE_USDC },
      { source: 'staking', tokenAmount: TOKENS.YT_xJOE_USDC },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1656547200,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.JOE,
    },
  },
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.JOE],
        received: [TOKENS.xJOE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.xJOE],
        received: [TOKENS.OT_xJOE, TOKENS.YT_xJOE],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_xJOE, TOKENS.USDC],
        received: [TOKENS.OT_xJOE_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_xJOE_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.JOE],
        received: [TOKENS.xJOE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.xJOE],
        received: [TOKENS.OT_xJOE, TOKENS.YT_xJOE],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_xJOE, TOKENS.USDC],
        received: [TOKENS.OT_xJOE_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_xJOE_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.JOE, TOKENS.USDC],
    baseTokenAddresses: [TOKENS.JOE, TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_xJOE_USDC },
      { source: 'wallet', tokenAmount: TOKENS.YT_xJOE },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1656547200,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.JOE,
    },
  },
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.JOE],
        received: [TOKENS.xJOE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.xJOE],
        received: [TOKENS.OT_xJOE, TOKENS.YT_xJOE],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_xJOE, TOKENS.USDC],
        received: [TOKENS.YT_xJOE_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_xJOE_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.JOE],
        received: [TOKENS.xJOE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.xJOE],
        received: [TOKENS.OT_xJOE, TOKENS.YT_xJOE],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_xJOE, TOKENS.USDC],
        received: [TOKENS.YT_xJOE_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_xJOE_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.JOE, TOKENS.USDC],
    baseTokenAddresses: [TOKENS.JOE, TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.YT_xJOE_USDC },
      { source: 'wallet', tokenAmount: TOKENS.OT_xJOE },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1656547200,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.JOE,
    },
  },
]
