export const ACTIONS = {
  PRE_MINT: 0,
  MINT: 1,
  REDEEM: 2,
  ADD_LIQUIDITY: 3,
  REMOVE_LIQUIDITY: 4,
  SWAP: 5,
  STAKE: 6,
  UNSTAKE: 7,
  DEPOSIT: 8,
  WRAP: 9,
}
