import { TOKENS, SPENDER_ADDRESS, PROTOCOLS } from './constants'
import { ACTIONS } from '../actions'

const YIELD_BEARING_ASSET = '0xbeb5d47a3f720ec0a390d04b4d41ed7d9688bc7f'
const TOKEN_ISSUER = '0xbeb5d47a3f720ec0a390d04b4d41ed7d9688bc7f-1703721600'

export default [
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.USDC],
        received: [TOKENS.qiUSDC],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiUSDC],
        received: [TOKENS.OT_qiUSDC, TOKENS.YT_qiUSDC],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.OT_qiUSDC_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiUSDC_USDC],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.YT_qiUSDC_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiUSDC_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.USDC],
        received: [TOKENS.qiUSDC],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiUSDC],
        received: [TOKENS.OT_qiUSDC, TOKENS.YT_qiUSDC],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.OT_qiUSDC_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiUSDC_USDC],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.YT_qiUSDC_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiUSDC_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.USDC],
    baseTokenAddresses: [TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_qiUSDC_USDC },
      { source: 'staking', tokenAmount: TOKENS.YT_qiUSDC_USDC },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.USDC,
    },
    denyInputTypes: ['dual'],
  },
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.USDC],
        received: [TOKENS.qiUSDC],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiUSDC],
        received: [TOKENS.OT_qiUSDC, TOKENS.YT_qiUSDC],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.OT_qiUSDC_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiUSDC_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.USDC],
        received: [TOKENS.qiUSDC],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiUSDC],
        received: [TOKENS.OT_qiUSDC, TOKENS.YT_qiUSDC],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.OT_qiUSDC_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiUSDC_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.USDC],
    baseTokenAddresses: [TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_qiUSDC_USDC },
      { source: 'wallet', tokenAmount: TOKENS.YT_qiUSDC },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.USDC,
    },
    denyInputTypes: ['dual'],
  },
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.USDC],
        received: [TOKENS.qiUSDC],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiUSDC],
        received: [TOKENS.OT_qiUSDC, TOKENS.YT_qiUSDC],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.YT_qiUSDC_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiUSDC_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.USDC],
        received: [TOKENS.qiUSDC],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiUSDC],
        received: [TOKENS.OT_qiUSDC, TOKENS.YT_qiUSDC],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiUSDC, TOKENS.USDC],
        received: [TOKENS.YT_qiUSDC_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiUSDC_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.USDC],
    baseTokenAddresses: [TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.YT_qiUSDC_USDC },
      { source: 'wallet', tokenAmount: TOKENS.OT_qiUSDC },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.USDC,
    },
    denyInputTypes: ['dual'],
  },
]
