import ZapCollection from '../collections/ZapCollection'
import { OneClickWrapper, YieldContract } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'

export default class Zap {
  constructor({
    transactions,
    singleTransactions,
    inputTokenAddresses,
    baseTokenAddresses,
    inputTokens,
    receive,
    network,
    purposes,
    expiryEpoch,
    tokenIssuer,
    spenderAddress,
    linkToObtain,
    baseTokens,
    denyInputTypes,
  }) {
    this.transactions = transactions
    this.singleTransactions = singleTransactions
    this.inputTokenAddresses = inputTokenAddresses
    this.baseTokenAddresses = baseTokenAddresses
    this.inputTokens = inputTokens
    this.receive = receive
    this.network = network
    this.purposes = purposes
    this.expiryEpoch = expiryEpoch
    this.tokenIssuer = tokenIssuer
    this.spenderAddress = spenderAddress
    this.linkToObtain = linkToObtain
    this.baseTokens = baseTokens
    this.denyInputTypes = denyInputTypes
  }

  static query() {
    return ZapCollection.all()
  }

  get id() {
    return `${this.receive.map((item) => item.tokenAmount.token.symbol).join('-')}-${
      this.expiryEpoch
    }`.replace(/\s/g, '')
  }

  get yieldBearingAsset() {
    return `${this.tokenIssuer.yieldBearingTokenAddress}-${this.tokenIssuer.expiryEpoch}`
  }

  get chainId() {
    return sdkChainId
  }

  get action() {
    return this.receive
      .filter((receivable) => receivable.source.className === 'StakingPool')
      .map((receivable) => receivable.tokenAmount.token.symbol.substr(0, 2))
      .sort()
      .join('')
  }

  contract({ signer, provider }) {
    const { forgeId, underlyingToken, expiryEpoch } = this.tokenIssuer
    const yieldContract = new YieldContract(forgeId, underlyingToken, expiryEpoch, sdkChainId)

    const actionEnum = { OT: 0, YT: 1, OTYT: 2 }[this.action]

    const wrapperMethods = new OneClickWrapper(yieldContract).methods({
      signer,
      provider: provider,
      chainId: sdkChainId,
    })

    return {
      ...wrapperMethods,
      simulateSingle({ tokenAmount, slippage }) {
        return wrapperMethods.simulateSingle(actionEnum, tokenAmount, slippage)
      },
      simulateDual({ tokenAmount, slippage }) {
        return wrapperMethods.simulateDual(actionEnum, tokenAmount, slippage)
      },
      send({ simulationDetails, slippage }) {
        return wrapperMethods.send(actionEnum, simulationDetails, slippage)
      },
      apr() {
        return wrapperMethods.apr(actionEnum)
      },
    }
  }
}
