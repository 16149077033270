import QueryCollection from '@/domains/QueryCollection'
import Zap from '@/domains/entities/Zap'
import Token from '@/domains/entities/Token'
import Transaction from '@/domains/entities/Transaction'
import TokenIssuer from '@/domains/entities/TokenIssuer'
import TokenAmount from '@/domains/entities/TokenAmount'
import Wallet from '@/domains/entities/Wallet'
import StakingPool from '@/domains/entities/StakingPool'
import zaps from '@/app-config/zaps'

export default class ZapCollection extends QueryCollection {
  static primaryKey = 'id'

  static all() {
    return new ZapCollection(
      ...zaps.map(
        (action) =>
          new Zap({
            ...action,
            inputTokens: action.inputTokenAddresses.map((addr) => Token.query().find(addr)),
            baseTokens: action.baseTokenAddresses.map((addr) => Token.query().find(addr)),
            receive: action.receive.map((item) => ({
              source: item.source === 'staking' ? new StakingPool({}) : new Wallet({}),
              tokenAmount: new TokenAmount(Token.query().find(item.tokenAmount), 0),
            })),
            expiryEpoch: action.expiryEpoch * 1000,
            tokenIssuer: TokenIssuer.query().find(action.tokenIssuer),
            transactions: action.transactions.map(
              (transaction) =>
                new Transaction({
                  ...transaction,
                  paid: transaction.paid.map(
                    (addr) => new TokenAmount(Token.query().find(addr), 0)
                  ),
                  received: transaction.received.map(
                    (addr) => new TokenAmount(Token.query().find(addr), 0)
                  ),
                })
            ),
            singleTransactions: action.singleTransactions.map(
              (transaction) =>
                new Transaction({
                  ...transaction,
                  paid: transaction.paid.map(
                    (addr) => new TokenAmount(Token.query().find(addr), 0)
                  ),
                  received: transaction.received.map(
                    (addr) => new TokenAmount(Token.query().find(addr), 0)
                  ),
                })
            ),
            denyInputTypes: action.denyInputTypes || [],
          })
      )
    )
  }
}
