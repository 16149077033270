import { TOKENS, SPENDER_ADDRESS, PROTOCOLS } from './constants'
import { ACTIONS } from '../actions'

const YIELD_BEARING_ASSET = '0x5c0401e81bc07ca70fad469b451682c0d747ef1c'
const TOKEN_ISSUER = '0x5c0401e81bc07ca70fad469b451682c0d747ef1c-1703721600'

export default [
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.AVAX],
        received: [TOKENS.qiAVAX],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiAVAX],
        received: [TOKENS.OT_qiAVAX, TOKENS.YT_qiAVAX],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.OT_qiAVAX_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiAVAX_USDC],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.YT_qiAVAX_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiAVAX_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.AVAX],
        received: [TOKENS.qiAVAX],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiAVAX],
        received: [TOKENS.OT_qiAVAX, TOKENS.YT_qiAVAX],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.OT_qiAVAX_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiAVAX_USDC],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.YT_qiAVAX_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiAVAX_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.AVAX, TOKENS.USDC],
    baseTokenAddresses: [TOKENS.AVAX, TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_qiAVAX_USDC },
      { source: 'staking', tokenAmount: TOKENS.YT_qiAVAX_USDC },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.USDC,
    },
  },
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.AVAX],
        received: [TOKENS.qiAVAX],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiAVAX],
        received: [TOKENS.OT_qiAVAX, TOKENS.YT_qiAVAX],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.OT_qiAVAX_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiAVAX_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.AVAX],
        received: [TOKENS.qiAVAX],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiAVAX],
        received: [TOKENS.OT_qiAVAX, TOKENS.YT_qiAVAX],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.OT_qiAVAX_USDC],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_qiAVAX_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.AVAX, TOKENS.USDC],
    baseTokenAddresses: [TOKENS.AVAX, TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_qiAVAX_USDC },
      { source: 'wallet', tokenAmount: TOKENS.YT_qiAVAX },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.USDC,
    },
  },
  {
    transactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.AVAX],
        received: [TOKENS.qiAVAX],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiAVAX],
        received: [TOKENS.OT_qiAVAX, TOKENS.YT_qiAVAX],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.YT_qiAVAX_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiAVAX_USDC],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.DEPOSIT,
        paid: [TOKENS.AVAX],
        received: [TOKENS.qiAVAX],
        protocol: PROTOCOLS.BENQI,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.qiAVAX],
        received: [TOKENS.OT_qiAVAX, TOKENS.YT_qiAVAX],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_qiAVAX, TOKENS.USDC],
        received: [TOKENS.YT_qiAVAX_USDC],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_qiAVAX_USDC],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.AVAX, TOKENS.USDC],
    baseTokenAddresses: [TOKENS.AVAX, TOKENS.USDC],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.YT_qiAVAX_USDC },
      { source: 'wallet', tokenAmount: TOKENS.OT_qiAVAX },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.USDC,
    },
  },
]
