import { avalanche } from '@/app-config/constants'

const tokens = avalanche.TOKENS
const pools = avalanche.POOLS
const pendle = avalanche.PENDLE

export const TOKENS = {
  // generic
  AVAX: tokens.AVAX,
  WAVAX: tokens.WAVAX,
  PENDLE: tokens.PENDLE,
  USDC: tokens.USDC,
  qiUSDC: tokens.QIUSDC,
  qiAVAX: tokens.QIAVAX,
  JOE: tokens.JOE,
  xJOE: tokens.XJOE,
  MEMO: tokens.MEMO,
  wMEMO: tokens.WMEMO,
  MIM: tokens.MIM,
  TIME: tokens.TIME,
  // lp tokens
  PENDLE_AVAX: tokens.JLP_WAVAX_PENDLE,
  OT_PA_PENDLE: pools.OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
  OT_qiUSDC_USDC: pools.OT_QIUSDC_28_DEC_2023_X_USDC,
  OT_qiAVAX_USDC: pools.OT_QIAVAX_28_DEC_2023_X_USDC,
  OT_xJOE_USDC: pools.OT_XJOE_30_JUN_2022_X_USDC,
  OT_wMEMO_MIM: pools.OT_WMEMO_24_FEB_2022_X_MIM,
  YT_PA_PENDLE: pools.YT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
  YT_qiUSDC_USDC: pools.YT_QIUSDC_28_DEC_2023_X_USDC,
  YT_qiAVAX_USDC: pools.YT_QIAVAX_28_DEC_2023_X_USDC,
  YT_xJOE_USDC: pools.YT_XJOE_30_JUN_2022_X_USDC,
  YT_wMEMO_MIM: pools.YT_WMEMO_24_FEB_2022_X_MIM,
  // ot tokens
  OT_PA: tokens.OT_JLP_WAVAX_PENDLE_28_DEC_2023,
  OT_qiUSDC: tokens.OT_QIUSDC_28_DEC_2023,
  OT_qiAVAX: tokens.OT_QIAVAX_28_DEC_2023,
  OT_xJOE: tokens.OT_XJOE_30_JUN_2022,
  OT_wMEMO: tokens.OT_WMEMO_24_FEB_2022,
  // yt tokens
  YT_PA: tokens.YT_JLP_WAVAX_PENDLE_28_DEC_2023,
  YT_qiUSDC: tokens.YT_QIUSDC_28_DEC_2023,
  YT_qiAVAX: tokens.YT_QIAVAX_28_DEC_2023,
  YT_xJOE: tokens.YT_XJOE_30_JUN_2022,
  YT_wMEMO: tokens.YT_WMEMO_24_FEB_2022,
}

export const SPENDER_ADDRESS = pendle.ZAP_SPENDER_ADDRESS

export const PROTOCOLS = {
  PENDLE: 'Pendle',
  TRADERJOE: 'TraderJoe',
  BENQI: 'BENQI',
  WONDERLAND: 'Wonderland',
}
