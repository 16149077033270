import { TOKENS, SPENDER_ADDRESS, PROTOCOLS } from './constants'
import { ACTIONS } from '../actions'

const YIELD_BEARING_ASSET = '0x3acd2ff1c3450bc8a9765afd8d0dea8e40822c86'
const TOKEN_ISSUER = '0x3acd2ff1c3450bc8a9765afd8d0dea8e40822c86-1703721600'

export default [
  {
    transactions: [
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.PENDLE, TOKENS.AVAX],
        received: [TOKENS.PENDLE_AVAX],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.PENDLE_AVAX],
        received: [TOKENS.OT_PA, TOKENS.YT_PA],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_PA, TOKENS.PENDLE],
        received: [TOKENS.OT_PA_PENDLE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_PA_PENDLE],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_PA, TOKENS.PENDLE],
        received: [TOKENS.YT_PA_PENDLE],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_PA_PENDLE],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.PENDLE, TOKENS.WAVAX],
        received: [TOKENS.PENDLE_AVAX],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.PENDLE_AVAX],
        received: [TOKENS.OT_PA, TOKENS.YT_PA],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_PA, TOKENS.PENDLE],
        received: [TOKENS.OT_PA_PENDLE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_PA_PENDLE],
        received: [],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_PA, TOKENS.PENDLE],
        received: [TOKENS.YT_PA_PENDLE],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_PA_PENDLE],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.PENDLE, TOKENS.AVAX],
    baseTokenAddresses: [TOKENS.WAVAX, TOKENS.PENDLE],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_PA_PENDLE },
      { source: 'staking', tokenAmount: TOKENS.YT_PA_PENDLE },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.PENDLE,
    },
  },
  {
    transactions: [
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.PENDLE, TOKENS.AVAX],
        received: [TOKENS.PENDLE_AVAX],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.PENDLE_AVAX],
        received: [TOKENS.OT_PA, TOKENS.YT_PA],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_PA, TOKENS.PENDLE],
        received: [TOKENS.OT_PA_PENDLE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_PA_PENDLE],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.PENDLE, TOKENS.WAVAX],
        received: [TOKENS.PENDLE_AVAX],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.PENDLE_AVAX],
        received: [TOKENS.OT_PA, TOKENS.YT_PA],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.OT_PA, TOKENS.PENDLE],
        received: [TOKENS.OT_PA_PENDLE],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.OT_PA_PENDLE],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.PENDLE, TOKENS.AVAX],
    baseTokenAddresses: [TOKENS.WAVAX, TOKENS.PENDLE],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.OT_PA_PENDLE },
      { source: 'wallet', tokenAmount: TOKENS.YT_PA },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.PENDLE,
    },
  },
  {
    transactions: [
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.PENDLE, TOKENS.AVAX],
        received: [TOKENS.PENDLE_AVAX],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.PENDLE_AVAX],
        received: [TOKENS.OT_PA, TOKENS.YT_PA],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_PA, TOKENS.PENDLE],
        received: [TOKENS.YT_PA_PENDLE],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_PA_PENDLE],
        received: [],
      },
    ],
    singleTransactions: [
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.PENDLE, TOKENS.WAVAX],
        received: [TOKENS.PENDLE_AVAX],
        protocol: PROTOCOLS.TRADERJOE,
      },
      {
        action: ACTIONS.MINT,
        paid: [TOKENS.PENDLE_AVAX],
        received: [TOKENS.OT_PA, TOKENS.YT_PA],
      },
      {
        action: ACTIONS.ADD_LIQUIDITY,
        paid: [TOKENS.YT_PA, TOKENS.PENDLE],
        received: [TOKENS.YT_PA_PENDLE],
        protocol: PROTOCOLS.PENDLE,
      },
      {
        action: ACTIONS.STAKE,
        paid: [TOKENS.YT_PA_PENDLE],
        received: [],
      },
    ],
    yieldBearingAsset: YIELD_BEARING_ASSET,
    tokenIssuer: TOKEN_ISSUER,
    inputTokenAddresses: [TOKENS.PENDLE, TOKENS.AVAX],
    baseTokenAddresses: [TOKENS.WAVAX, TOKENS.PENDLE],
    receive: [
      { source: 'staking', tokenAmount: TOKENS.YT_PA_PENDLE },
      { source: 'wallet', tokenAmount: TOKENS.OT_PA },
    ],
    network: 'avalanche',
    chainId: 43114,
    expiryEpoch: 1703721600,
    spenderAddress: SPENDER_ADDRESS,
    linkToObtain: {
      tokenAddress: TOKENS.PENDLE,
    },
  },
]
